<template>
  <transition name="modalLogin">
    <div class="login-modal" :class="localState.modalStatus">
      <template v-if="this.$scopedSlots.closeButton">
        <slot name="closeButton"></slot>
      </template>
      <div v-else class="modal-close-button" @click="$parent.close()"><span></span></div>
      <div class="modal-header">
        <img :src="`${cdnUrl}/assets/images/common/logos/logo.svg`">
      </div>
      <div class="modal-body">
        <p>ライブラリに本を追加するには</p>
        <p>ログインをしてからお試しください。</p>
      </div>
      <div class="modal-footer">
        <p class="signup"><a :class="localState.buttonActive" @click="modalLogin('/login');">ログイン画面へ移動</a></p>
        <p class="signup"><a href="/signup" target="_blank">初めてご利用の方（新規登録）</a></p>
        <div class="other-site-id">
          <ul>
            <li class="sns-id amazon-id">
              <a :class="localState.buttonActive" @click="modalLogin('amazon');">Amazon</a>
            </li>
            <li class="sns-id google-id">
              <a :class="localState.buttonActive" @click="modalLogin('/snslogin/google');">Google</a>
            </li>
            <li class="sns-id facebook-id">
              <a :class="localState.buttonActive" @click="modalLogin('/snslogin/facebook');">Facebook</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="loading-wrapper">
        <span class="load-spinner"></span>
      </div>
    </div>
  </transition>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: 'modal-login',
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      localState: {
        currentPath: '',
        facebookUrl: '',
        pageType: '',
        buttonActive: 'enabled',
        modalStatus: ''
      }
    };
  },
  created() {
    // 現在のページURL情報をセット
    this.localState.currentPath = location.pathname;
    this.getPageType();
  },
  methods: {
    ...mapActions([
      'loginStatusCheck'
    ]),
    // ボタンを無効化/ローディング表示
    loadingInit: function () {
      this.localState.buttonActive = 'disabled';
      this.localState.modalStatus = 'loading';
    },
    // ボタンを有効化/ローディング表示を初期化
    completeInit: function () {
      this.localState.buttonActive = 'enabled';
      this.localState.modalStatus = '';
    },
    // 実行画面を判定
    getPageType: function () {
      let pageType = 'item';
      // 本棚スキャンからのモーダルログイン
      if (this.localState.currentPath === '/estimate/search') {
        pageType = 'scan';
        // ログイン後の戻り先をセット
        this.setAuthReferer('/wait-screen?windowType=close');
      }
      this.localState.pageType = pageType;
    },
    // 実行画面毎に画面遷移時のウィンドウモードを切り替える
    modalLogin: async function (path) {
      const transitionPath = path;
      const self = this;
      // ボタンを無効化/ローディング表示
      self.loadingInit();

      // 本棚スキャンの場合は別ウィンドウで開いた画面で遷移
      if (self.localState.pageType === 'scan') {
        if (transitionPath === 'amazon') {
          var loginWindow = window.open('/wait-screen?windowType=amazon', '_blank');
        } else {
          // iOSでのポップアップブロックを回避するためサーバ通信処理以前で実行
          var loginWindow = window.open('/wait-screen', '_blank');
        }
        // 指定パスへ遷移
        loginWindow.addEventListener('load', async function (event) {
          loginWindow.location.href = transitionPath;
          // unloadではwindow.open時に発火してしまうため、タイマーでclosedプロパティを監視する
          const timer = setInterval(() => {
            if (!loginWindow.closed) return;
            clearInterval(timer);
            // ログイン状態を更新
            self.loginStatusCheck();
          }, 100);
        }, false);
      } else {
        await self.setAuthReferer();
        if (transitionPath === 'amazon') {
          location.href = '/wait-screen?windowType=amazon';
        } else {
          location.href = transitionPath;
        }
      }
    },
    // セッションのauth_refererに戻り先をセットする
    setAuthReferer: function (path = null) {
      const self = this;
      return axios.post('/api/account/setAuthReferer', {
        returnPath: path
      })
          .then(function (response) {
            return true;
          }).catch(error => {
            self.completeInit();
            return false;
          });
    }
  }
}
</script>

<style scoped>
.loading-wrapper {
  display: none;
}

.loading {
  position: relative;
}

.loading .loading-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  display: block;
}

.loading .loading-wrapper .load-spinner {
  position: absolute;
}

.modal-body .inner {
  max-width: 100%;
  min-width: 100%;
}

.modal-body button {
  border-radius: 4px;
  padding: 10px 15px;
}

.modal-body a {
  font-size: 11px;
}

.modal-footer {
  padding: 0 0 20px;
}

.modal-footer a.disabled {
  pointer-events: none;
}

.other-site-id {
  width: 100%;
}

.other-site-id a {
  cursor: pointer;
}

#app .login-modal .modal-body {
  padding-bottom: 20px;
  border-bottom: 1px solid #DDD;
}

.login-modal {
  width: 90%;
  margin: 0 auto;
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
}

.login-modal {
  width: 90%;
  margin: 0 auto;
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
}

.login-modal .modal-close-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  float: right;
}

.login-modal .modal-close-button span::before,
.login-modal .modal-close-button span::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 84%;
  height: 16%;
  margin: -8% 0 0 -42%;
  background: #090000;
}

.login-modal .modal-close-button span::before {
  transform: rotate(-45deg);
}

.login-modal .modal-close-button span::after {
  transform: rotate(45deg);
}

.login-modal .modal-header {
  text-align: center;
  clear: both;
}

.login-modal .modal-header img {
  width: 50px;
}

.login-modal .modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
  overflow-y: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #DDD;
}

.login-modal .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-modal .modal-footer .signup {
  width: 100%;
  padding: 10px 15px;
  background-color: #000;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
}

.login-modal .modal-footer .signup a {
  display: block;
  text-align: center;
  color: #FFF;
  cursor: pointer;
}
</style>

